export const ages = [
    { name: 'Senior', value: 'senior' },
    { name: 'Adult', value: 'adult' },
    { name: 'Teen', value: 'teen' },
    { name: 'Pre-teen', value: 'pre-teen' },
    { name: 'Toddler', value: 'toddler' },
    { name: 'Infant', value: 'infant' },
];

export const angles = [
    { name: 'Back', value: 'back' },
    { name: 'Front', value: 'front' },
    { name: 'Side', value: 'side' },
];

export const aspectRatios = [
    { name: '1024x1024 (1:1)', value: '1:1' },
    { name: '1152x896 (9:7)', value: '9:7' },
    { name: '896x1152 (7:9)', value: '7:9' },
    { name: '1216x832 (19:13)', value: '19:13' },
    { name: '832x1216 (13:19)', value: '13:19' },
    { name: '1344x768 (7:4)', value: '7:4' },
    { name: '768x1344 (4:7)', value: '4:7' },
    { name: '1536x640 (12:5)', value: '12:5' },
    { name: '640x1536 (5:12)', value: '5:12' },
];

export const backgrounds = [
    { name: 'Studio', value: 'studio' },
    { name: 'Park', value: 'park' },
    { name: 'Beach', value: 'beach' },
    { name: 'European Street', value: 'european_street' },
    { name: 'Lounge', value: 'lounge' },
    { name: 'Megapolis', value: 'megapolis' },
    { name: 'Old Money', value: 'old_money' },
    { name: 'Paris', value: 'paris' },
    { name: 'Desert', value: 'desert' },
];

export const models = [
    { value: 'central_asian' },
    { value: 'middle_eastern' },
    { value: 'western_european' },
    { value: 'african_american' },
    { value: 'australian' },
    { value: 'indian' },
    { value: 'central_american' },
    { value: 'mongolian' },
    { value: 'west_african' },
];

export const genders = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
];

export const photoTypes = [
    { name: 'Headshot', value: 'headshot' },
    { name: 'Full body', value: 'fullbody' },
    { name: 'Close up', value: 'closeup' },
];

export const versions = [
    { name: 'v1', value: '1' },
    { name: 'v2', value: '2' },
    // { name: 'v1-v2 (2x2)', value: '1,2' },
    // { name: 'v4 beta', value: 'v4_beta' },
];
